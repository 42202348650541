import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Layout, Space, Spin, Typography } from "antd";
import { Footer } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getMonthlySummary } from "../../services/timelog.crud";
import { useAuthValue } from "../../context/AuthContext";

const localizer = dayjsLocalizer(dayjs);

export const MonthlySummary = () => {
  const { Text } = Typography;

  const { currentUser } = useAuthValue();
  const [monthData, setMonthData] = useState(null);
  const [eventData, setEventsData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getEventData = (data, index) => {
    const event = {
      id: index,
      title: data.hours,
      ...(data.startDate && { start: dayjs(data.startDate, "YYYY/MM/DD") }),
      ...(data.startDate && { end: dayjs(data.startDate, "YYYY/MM/DD") }),
      ...(data.leave && { leave: true }),
      ...(data.holiday && { holiday: true }),
      allday: false,
    };
    return event;
  };

  const dayPropGetter = (date) => {
    const day = date.getDay();
    const dateString = date.toDateString(); // Convert the date to a comparable string format
    // Check if any event on the day has the isHoliday flag
    const isLeave =
      eventData &&
      eventData.some((event) => {
        const eventStartDate = new Date(event.start).toDateString();
        return eventStartDate === dateString && event.leave;
      });
    const isHoliday =
      eventData &&
      eventData.some((event) => {
        const eventStartDate = new Date(event.start).toDateString();
        return eventStartDate === dateString && event.holiday;
      });
    if (day === 6 || day === 0 || isHoliday) {
      // 6 = Saturday, 0 = Sunday
      return {
        style: {
          backgroundColor: "#e6e6e6", // Light grey background
          color: "#ccc", // Grey out text
        },
      };
    }
    if (isLeave) {
      return {
        style: {
          backgroundColor: "#c9184a80", // Light grey background
        },
      };
    }
    return {};
  };

  useEffect(() => {
    setIsLoading(true);
    getMonthlySummary(currentUser.uid, dayjs(date).format("DD/MM/YYYY")).then((res) => {
      Promise.all(
        res.timelog.map((item, index) => {
          return getEventData(item, index);
        }),
      )
        .then((res) => {
          const filterData = res.filter((item) => item.title != "0.00");
          setEventsData(filterData);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(true);
        });
      setMonthData(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    // @ts-ignore
    <Layout
      style={{
        background: "transparent",
        display: "flex",
        justifyContent: "center",
        paddingInline: "20vw",
        opacity: isLoading ? 0.5 : 1,
      }}
    >
      {isLoading && (
        <Space style={{ position: "absolute", left: "50%", zIndex: 111 }}>
          <Spin />
        </Space>
      )}
      <Calendar
        // @ts-ignore
        views={{ month: true }}
        selectable
        localizer={localizer}
        defaultDate={date}
        defaultView="month"
        events={eventData}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "66vh", width: "58vw" }}
        components={{
          toolbar: CustomToolbar,
        }}
        onNavigate={(newDate) => {
          setIsLoading(true);
          setDate(newDate);
        }}
        dayPropGetter={dayPropGetter}
        eventPropGetter={(event) => ({
          style: {
            display: "flex",
            fontWeight: 400,
            justifyContent: "center",
            alignItems: "center",
            fontSize: "28px",
            background: "transparent",
            color: "black",
            marginTop: 8,
            padding: 0,
          },
        })}
      />
      <Footer
        style={{
          background: "#c9184a",
          color: "white",
          fontWeight: "bold",
          height: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "58vw",
        }}
      >
        {monthData && (
          <>
            <Text style={{ color: "white" }}>Total Working Days: {monthData.workingDays}</Text>
            <Text style={{ color: "white" }}>Total Hours: {monthData.totalWorkHour}</Text>
            <Text style={{ color: "white" }}>
              Shortage: <Text type="warning">{monthData.shortage}</Text>
            </Text>
          </>
        )}
      </Footer>
      <Space direction="horizontal" style={{ marginTop: 8 }}>
        <Space direction="horizontal">
          <div style={{ background: "#c9184a", height: 15, width: 15, borderRadius: 15 }} />
          <Text strong>Leave</Text>
        </Space>
        <Space direction="horizontal">
          <div style={{ background: "#e6e6e6", height: 15, width: 15, borderRadius: 15 }} />
          <Text strong>Holiday</Text>
        </Space>
      </Space>
    </Layout>
  );
};

function CustomToolbar({ onNavigate, onView, view, date, label }) {
  const handleNavigate = (e) => {
    onNavigate(e);
  };

  return (
    <>
      <Space direction="horizontal" style={{ justifyContent: "center", marginBottom: 16 }}>
        <Space>{/* <Button onClick={() => handleNavigate("TODAY")}>Today</Button> */}</Space>
        <Space>
          <Button type="text" onClick={() => handleNavigate("PREV")}>
            <LeftOutlined />
          </Button>
          <div>{label}</div>
          <Button type="text" onClick={() => handleNavigate("NEXT")}>
            <RightOutlined />
          </Button>
        </Space>
      </Space>
    </>
  );
}
